<!--
  Display the users collections in BLKDRP
-->
<template>

  <div class="portfolio-container item-view">
    <div class="bottom">
      <div class="tabs">
        <h4>
          Collections
        </h4>
      </div>
      <div class="tables-container">
        <TokenTable
          v-if="shown === 'collections'"
          :data="collectionsFormatted"
          :additionalHeaders="headers"
          @view="viewCollection"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getDocs, query, orderBy } from 'firebase/firestore'
import { mapGetters, mapActions } from 'vuex'
export default {
  async created () {
    await this.claimDataCollectionsListener()
    this.getAllListedTokens()
  },
  data () {
    return {
      shown: 'collections',
      headers: [
        {
          text: 'Variants',
          value: 'types'
        },
        {
          text: 'Cap',
          value: 'cap'
        },
        {
          text: 'Claims',
          value: 'claims'
        },
        {
          text: 'Claimed',
          value: 'claimed'
        }
      ],

      collections: []
    }
  },
  computed: {
    ...mapGetters([
      'userProfile',
      'marketData',
      'claimDataCollections'
    ]),
    collectionsFormatted () {
      const formatted = []
      this.collections.forEach((collection) => {
        const copy = collection
        copy.image = this.collectionImagePath(collection.id, 64)
        copy.types = this.marketData && this.marketData[collection.id] ? this.marketData[collection.id].types.length : ''
        copy.cap = this.marketData && this.marketData[collection.id] ? this.marketData[collection.id].cap : ''
        copy.claims = this.claimDataCollections && this.claimDataCollections[collection.id] ? this.claimDataCollections[collection.id].totalClaims : ''
        copy.claimed = this.claimDataCollections && this.claimDataCollections[collection.id] ? this.claimDataCollections[collection.id].totalClaimed : ''
        formatted.push(copy)
      })
      return formatted
    }
  },
  methods: {
    ...mapActions([
      'claimDataCollectionsListener'
    ]),

    async getAllListedTokens () {
      const snapshot = await getDocs(query(this.$fb.tokenContracts, orderBy('name')))
      snapshot.forEach(async (token) => {
        const copy = token.data()
        copy.id = token.id
        this.collections.push(copy)
      })
    },
    viewCollection (target) {
      this.$router.push(`/distro/collections/${target.id}`)
    },
    updateSelection (selections) {
      this.selectedTokens = selections
    }
  }
}
</script>

<style lang="scss" scoped>
.header-buttons {
  list-style: none;
  margin: 0;
  padding: 0;
}
.tables-container {
  position: relative;
}
</style>
